.skin-blue .main-header .navbar {
  background-color: #3c8dbc; }

.skin-blue .main-header .navbar .nav > li > a {
  color: #fff; }

.skin-blue .main-header .navbar .nav > li > a:hover, .skin-blue .main-header .navbar .nav > li > a:active, .skin-blue .main-header .navbar .nav > li > a:focus, .skin-blue .main-header .navbar .nav .open > a, .skin-blue .main-header .navbar .nav .open > a:hover, .skin-blue .main-header .navbar .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6; }

.skin-blue .main-header .navbar .navbar-custom-menu > .nav {
  margin-right: 10px; }

.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1); }

.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #367fa9; }

@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff; }
  .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #367fa9; } }

.skin-blue .main-header .logo {
  background-color: #367fa9;
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-blue .main-header .logo > a {
  color: #fff; }

.skin-blue .main-header .logo:hover {
  background: #357ca5; }

.skin-blue .main-header li.user-header {
  background-color: #3c8dbc; }

.skin-blue .content-header {
  background: transparent; }

.skin-blue .user-panel > .info, .skin-blue .user-panel > .info > a {
  color: #fff; }

.skin-blue .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  margin-right: 1px; }

.skin-blue .sidebar-menu > li > a:hover, .skin-blue .sidebar-menu > li.active > a {
  color: #fff;
  background: #1e282c;
  border-left-color: #3c8dbc; }

.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
  background: #222d32; }

.skin-blue .sidebar a {
  color: #b8c7ce; }

.skin-blue .sidebar a:hover {
  text-decoration: none; }

.skin-blue .treeview-menu > li > a {
  color: #8aa4af; }

.skin-blue .treeview-menu > li.active > a, .skin-blue .treeview-menu > li > a:hover {
  color: #fff; }

.skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px; }

.skin-blue .sidebar-form input[type="text"], .skin-blue .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
  transition: all 0.3s cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.skin-blue .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important; }

.skin-blue .sidebar-form input[type="text"]:focus, .skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.skin-blue .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important; }

.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #3c8dbc;
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-blue.layout-top-nav .main-header > .logo > a {
  color: #fff; }

.skin-blue.layout-top-nav .main-header > .logo:hover {
  background: #3b8ab8; }

.skin-black .main-header {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.skin-black .main-header .navbar-toggle {
  color: #333; }

.skin-black .main-header .navbar-brand {
  color: #333;
  border-right: 1px solid #eee; }

.skin-black .main-header > .navbar {
  background-color: #fff; }

.skin-black .main-header > .navbar .nav > li > a {
  color: #333; }

.skin-black .main-header > .navbar .nav > li > a:hover, .skin-black .main-header > .navbar .nav > li > a:active, .skin-black .main-header > .navbar .nav > li > a:focus, .skin-black .main-header > .navbar .nav .open > a, .skin-black .main-header > .navbar .nav .open > a:hover, .skin-black .main-header > .navbar .nav .open > a:focus {
  background: #fff;
  color: #999; }

.skin-black .main-header > .navbar .navbar-custom-menu > .nav {
  margin-right: 10px; }

.skin-black .main-header > .navbar .sidebar-toggle {
  color: #333; }

.skin-black .main-header > .navbar .sidebar-toggle:hover {
  color: #999;
  background: #fff; }

.skin-black .main-header > .navbar > .sidebar-toggle {
  color: #333;
  border-right: 1px solid #eee; }

.skin-black .main-header > .navbar .navbar-nav > li > a {
  border-right: 1px solid #eee; }

.skin-black .main-header > .navbar .navbar-custom-menu .navbar-nav > li > a, .skin-black .main-header > .navbar .navbar-right > li > a {
  border-left: 1px solid #eee;
  border-right-width: 0; }

.skin-black .main-header > .logo {
  background-color: #fff;
  color: #333;
  border-bottom: 0 solid transparent;
  border-right: 1px solid #eee; }

.skin-black .main-header > .logo > a {
  color: #333; }

.skin-black .main-header > .logo:hover {
  background: #fcfcfc; }

@media (max-width: 767px) {
  .skin-black .main-header > .logo {
    background-color: #222;
    color: #fff;
    border-bottom: 0 solid transparent;
    border-right: none; }
  .skin-black .main-header > .logo > a {
    color: #fff; }
  .skin-black .main-header > .logo:hover {
    background: #1f1f1f; } }

.skin-black .main-header li.user-header {
  background-color: #222; }

.skin-black .content-header {
  background: transparent;
  box-shadow: none; }

.skin-black .user-panel > .image > img {
  border: 1px solid #444; }

.skin-black .user-panel > .info, .skin-black .user-panel > .info > a {
  color: #eee; }

.skin-black .main-sidebar, .skin-black .left-side, .skin-black .wrapper {
  background: #222; }

.skin-black .sidebar > .sidebar-menu > li.header {
  background: #1d1d1d;
  color: rgba(255, 255, 255, 0.4); }

.skin-black .sidebar > .sidebar-menu > li > a {
  margin-right: 1px;
  border-left: 3px solid transparent; }

.skin-black .sidebar > .sidebar-menu > li > a:hover, .skin-black .sidebar > .sidebar-menu > li.active > a {
  color: #fff;
  background: #444;
  border-left-color: #fff; }

.skin-black .sidebar > .sidebar-menu > li > .treeview-menu {
  background: #333; }

.skin-black .sidebar a {
  color: #eee; }

.skin-black .sidebar a:hover {
  text-decoration: none; }

.skin-black .treeview-menu > li > a {
  color: #ccc; }

.skin-black .treeview-menu > li.active > a, .skin-black .treeview-menu > li > a:hover {
  color: #fff; }

.skin-black .sidebar-form {
  border-radius: 3px;
  border: 1px solid #3c3c3c;
  margin: 10px 10px; }

.skin-black .sidebar-form input[type="text"], .skin-black .sidebar-form .btn {
  box-shadow: none;
  background-color: #3c3c3c;
  border: 1px solid transparent;
  height: 35px;
  transition: all 0.3s cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.skin-black .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important; }

.skin-black .sidebar-form input[type="text"]:focus, .skin-black .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.skin-black .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.skin-black .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important; }

.skin-green .main-header .navbar {
  background-color: #00a65a; }

.skin-green .main-header .navbar .nav > li > a {
  color: #fff; }

.skin-green .main-header .navbar .nav > li > a:hover, .skin-green .main-header .navbar .nav > li > a:active, .skin-green .main-header .navbar .nav > li > a:focus, .skin-green .main-header .navbar .nav .open > a, .skin-green .main-header .navbar .nav .open > a:hover, .skin-green .main-header .navbar .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6; }

.skin-green .main-header .navbar .navbar-custom-menu > .nav {
  margin-right: 10px; }

.skin-green .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-green .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1); }

.skin-green .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-green .main-header .navbar .sidebar-toggle:hover {
  background-color: #008d4c; }

@media (max-width: 767px) {
  .skin-green .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .skin-green .main-header .navbar .dropdown-menu li a {
    color: #fff; }
  .skin-green .main-header .navbar .dropdown-menu li a:hover {
    background: #008d4c; } }

.skin-green .main-header .logo {
  background-color: #008d4c;
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-green .main-header .logo > a {
  color: #fff; }

.skin-green .main-header .logo:hover {
  background: #008749; }

.skin-green .main-header li.user-header {
  background-color: #00a65a; }

.skin-green .content-header {
  background: transparent; }

.skin-green .user-panel > .info, .skin-green .user-panel > .info > a {
  color: #fff; }

.skin-green .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-green .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  margin-right: 1px; }

.skin-green .sidebar-menu > li > a:hover, .skin-green .sidebar-menu > li.active > a {
  color: #fff;
  background: #1e282c;
  border-left-color: #00a65a; }

.skin-green .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-green .wrapper, .skin-green .main-sidebar, .skin-green .left-side {
  background: #222d32; }

.skin-green .sidebar a {
  color: #b8c7ce; }

.skin-green .sidebar a:hover {
  text-decoration: none; }

.skin-green .treeview-menu > li > a {
  color: #8aa4af; }

.skin-green .treeview-menu > li.active > a, .skin-green .treeview-menu > li > a:hover {
  color: #fff; }

.skin-green .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px; }

.skin-green .sidebar-form input[type="text"], .skin-green .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
  transition: all 0.3s cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.skin-green .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important; }

.skin-green .sidebar-form input[type="text"]:focus, .skin-green .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.skin-green .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.skin-green .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important; }

.skin-red .main-header .navbar {
  background-color: #dd4b39; }

.skin-red .main-header .navbar .nav > li > a {
  color: #fff; }

.skin-red .main-header .navbar .nav > li > a:hover, .skin-red .main-header .navbar .nav > li > a:active, .skin-red .main-header .navbar .nav > li > a:focus, .skin-red .main-header .navbar .nav .open > a, .skin-red .main-header .navbar .nav .open > a:hover, .skin-red .main-header .navbar .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6; }

.skin-red .main-header .navbar .navbar-custom-menu > .nav {
  margin-right: 10px; }

.skin-red .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-red .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1); }

.skin-red .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-red .main-header .navbar .sidebar-toggle:hover {
  background-color: #d73925; }

@media (max-width: 767px) {
  .skin-red .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .skin-red .main-header .navbar .dropdown-menu li a {
    color: #fff; }
  .skin-red .main-header .navbar .dropdown-menu li a:hover {
    background: #d73925; } }

.skin-red .main-header .logo {
  background-color: #d73925;
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-red .main-header .logo > a {
  color: #fff; }

.skin-red .main-header .logo:hover {
  background: #d33724; }

.skin-red .main-header li.user-header {
  background-color: #dd4b39; }

.skin-red .content-header {
  background: transparent; }

.skin-red .user-panel > .info, .skin-red .user-panel > .info > a {
  color: #fff; }

.skin-red .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-red .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  margin-right: 1px; }

.skin-red .sidebar-menu > li > a:hover, .skin-red .sidebar-menu > li.active > a {
  color: #fff;
  background: #1e282c;
  border-left-color: #dd4b39; }

.skin-red .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-red .wrapper, .skin-red .main-sidebar, .skin-red .left-side {
  background: #222d32; }

.skin-red .sidebar a {
  color: #b8c7ce; }

.skin-red .sidebar a:hover {
  text-decoration: none; }

.skin-red .treeview-menu > li > a {
  color: #8aa4af; }

.skin-red .treeview-menu > li.active > a, .skin-red .treeview-menu > li > a:hover {
  color: #fff; }

.skin-red .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px; }

.skin-red .sidebar-form input[type="text"], .skin-red .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
  transition: all 0.3s cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.skin-red .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important; }

.skin-red .sidebar-form input[type="text"]:focus, .skin-red .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.skin-red .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.skin-red .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important; }

.skin-yellow .main-header .navbar {
  background-color: #f39c12; }

.skin-yellow .main-header .navbar .nav > li > a {
  color: #fff; }

.skin-yellow .main-header .navbar .nav > li > a:hover, .skin-yellow .main-header .navbar .nav > li > a:active, .skin-yellow .main-header .navbar .nav > li > a:focus, .skin-yellow .main-header .navbar .nav .open > a, .skin-yellow .main-header .navbar .nav .open > a:hover, .skin-yellow .main-header .navbar .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6; }

.skin-yellow .main-header .navbar .navbar-custom-menu > .nav {
  margin-right: 10px; }

.skin-yellow .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-yellow .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1); }

.skin-yellow .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-yellow .main-header .navbar .sidebar-toggle:hover {
  background-color: #e08e0b; }

@media (max-width: 767px) {
  .skin-yellow .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .skin-yellow .main-header .navbar .dropdown-menu li a {
    color: #fff; }
  .skin-yellow .main-header .navbar .dropdown-menu li a:hover {
    background: #e08e0b; } }

.skin-yellow .main-header .logo {
  background-color: #e08e0b;
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-yellow .main-header .logo > a {
  color: #fff; }

.skin-yellow .main-header .logo:hover {
  background: #db8b0b; }

.skin-yellow .main-header li.user-header {
  background-color: #f39c12; }

.skin-yellow .content-header {
  background: transparent; }

.skin-yellow .user-panel > .info, .skin-yellow .user-panel > .info > a {
  color: #fff; }

.skin-yellow .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-yellow .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  margin-right: 1px; }

.skin-yellow .sidebar-menu > li > a:hover, .skin-yellow .sidebar-menu > li.active > a {
  color: #fff;
  background: #1e282c;
  border-left-color: #f39c12; }

.skin-yellow .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-yellow .wrapper, .skin-yellow .main-sidebar, .skin-yellow .left-side {
  background: #222d32; }

.skin-yellow .sidebar a {
  color: #b8c7ce; }

.skin-yellow .sidebar a:hover {
  text-decoration: none; }

.skin-yellow .treeview-menu > li > a {
  color: #8aa4af; }

.skin-yellow .treeview-menu > li.active > a, .skin-yellow .treeview-menu > li > a:hover {
  color: #fff; }

.skin-yellow .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px; }

.skin-yellow .sidebar-form input[type="text"], .skin-yellow .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
  transition: all 0.3s cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.skin-yellow .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important; }

.skin-yellow .sidebar-form input[type="text"]:focus, .skin-yellow .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.skin-yellow .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.skin-yellow .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important; }

.skin-purple .main-header .navbar {
  background-color: #605ca8; }

.skin-purple .main-header .navbar .nav > li > a {
  color: #fff; }

.skin-purple .main-header .navbar .nav > li > a:hover, .skin-purple .main-header .navbar .nav > li > a:active, .skin-purple .main-header .navbar .nav > li > a:focus, .skin-purple .main-header .navbar .nav .open > a, .skin-purple .main-header .navbar .nav .open > a:hover, .skin-purple .main-header .navbar .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6; }

.skin-purple .main-header .navbar .navbar-custom-menu > .nav {
  margin-right: 10px; }

.skin-purple .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-purple .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1); }

.skin-purple .main-header .navbar .sidebar-toggle {
  color: #fff; }

.skin-purple .main-header .navbar .sidebar-toggle:hover {
  background-color: #555299; }

@media (max-width: 767px) {
  .skin-purple .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .skin-purple .main-header .navbar .dropdown-menu li a {
    color: #fff; }
  .skin-purple .main-header .navbar .dropdown-menu li a:hover {
    background: #555299; } }

.skin-purple .main-header .logo {
  background-color: #555299;
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-purple .main-header .logo > a {
  color: #fff; }

.skin-purple .main-header .logo:hover {
  background: #545096; }

.skin-purple .main-header li.user-header {
  background-color: #605ca8; }

.skin-purple .content-header {
  background: transparent; }

.skin-purple .user-panel > .info, .skin-purple .user-panel > .info > a {
  color: #fff; }

.skin-purple .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-purple .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  margin-right: 1px; }

.skin-purple .sidebar-menu > li > a:hover, .skin-purple .sidebar-menu > li.active > a {
  color: #fff;
  background: #1e282c;
  border-left-color: #605ca8; }

.skin-purple .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-purple .wrapper, .skin-purple .main-sidebar, .skin-purple .left-side {
  background: #222d32; }

.skin-purple .sidebar a {
  color: #b8c7ce; }

.skin-purple .sidebar a:hover {
  text-decoration: none; }

.skin-purple .treeview-menu > li > a {
  color: #8aa4af; }

.skin-purple .treeview-menu > li.active > a, .skin-purple .treeview-menu > li > a:hover {
  color: #fff; }

.skin-purple .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px; }

.skin-purple .sidebar-form input[type="text"], .skin-purple .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
  transition: all 0.3s cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.skin-purple .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important; }

.skin-purple .sidebar-form input[type="text"]:focus, .skin-purple .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.skin-purple .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.skin-purple .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important; }

/*# sourceMappingURL=skin.css.map */
